const MythBackground = () => {
  return (
    <div
      className="pointer-events-none absolute inset-0 -z-10 col-span-full h-full w-full bg-beige md:col-span-16 md:col-start-3 lg:col-span-12 lg:col-start-5"
      aria-hidden="true"
    />
  )
}

export default MythBackground

import Image from 'next/image'

type OverlayTooltipButtonProps = {
  label: string
  onClick: () => void
}

const OverlayTooltipButton = ({ label, onClick }: OverlayTooltipButtonProps) => {
  return (
    <div
      className="group relative flex cursor-pointer items-center gap-x-3 border border-black px-4 py-2 text-black"
      onClick={onClick}
    >
      <div className="absolute bottom-0 left-0 right-0 -z-10 h-1 bg-orange transition-all duration-500 ease-in-out group-hover:h-full"></div>
      <div>
        <Image width={50} height={20} alt="" src={'/assets/hand-pointer.svg'} />
      </div>
      <div className="font-serif text-16 font-bold md:text-18">{label}</div>
    </div>
  )
}

export default OverlayTooltipButton

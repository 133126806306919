'use client'

import NextImage from 'next/image'
import { useState } from 'react'
import type { Image } from 'sanity'

import { SanityImage } from '@/components/shared/sanity-image'
import { cn } from '@/lib/utils'
import { type TipsOverlay } from '@/types'

import Button from '../ui/button'
import Divider from '../ui/divider'
import OverlayTooltipButton from '../ui/tips-overlay-button'

const TipsOverlay = ({ label, tips }: TipsOverlay) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [currStep, setCurrStep] = useState<number>(0)

  const previousStep = () => {
    setCurrStep((prev: number) => (prev === 0 ? prev : prev - 1))
  }

  const nextStep = () => {
    setCurrStep((prev: number) => (prev === tips.length - 1 ? prev : prev + 1))
  }

  return (
    <>
      {/* OVERLAY  */}
      {
        <div
          className={cn(
            'fixed inset-x-0 bottom-0 z-40 w-full gap-y-9 transition-all duration-700',
            {
              'translate-y-96 opacity-0': !showOverlay,
              'translate-y-0 opacity-100': showOverlay,
            },
          )}
        >
          {/* CLOSE */}
          <div className="flex w-full justify-center">
            <Button
              onClick={() => {
                setShowOverlay((prev) => !prev)
              }}
              variant="ghost"
              className={`bottom-9 left-0 w-[160px] border-2 border-black bg-beige px-5 py-2 font-sans text-13 font-semibold uppercase leading-6 tracking-[3.25px] text-black`}
            >
              <div className="flex items-center gap-x-3">
                <NextImage src="/assets/icons/close.svg" width={20} height={20} alt="" />
                Close
              </div>
            </Button>
          </div>

          <div className={cn('z-40 w-full bg-beige')}>
            <Divider type="thick-thin" />
            <div className="container mx-auto flex justify-between gap-x-28 p-5 md:gap-x-12 md:p-0 md:px-20 md:py-16 lg:px-28 xl:gap-x-32 xl:pl-44 xl:pr-60">
              <div className="flex flex-col gap-y-4 md:gap-y-2">
                <div className="bold font-sans text-15 font-bold uppercase tracking-10">
                  {tips[currStep].subtitle}
                </div>
                <div className="font-serif text-30">{tips[currStep].title}</div>
                <p className="min-h-32 md:mb-12 md:mt-7 md:min-h-24 md:max-w-[432px] lg:max-w-[532px]">
                  {tips[currStep].copy}
                </p>

                {/* MOBILE */}
                <div className="flex items-end justify-between md:hidden">
                  <div className="">
                    {tips[currStep].image && (
                      <SanityImage
                        data={tips[currStep].image as Image}
                        className="h-[187px] w-[187px]"
                      />
                    )}
                  </div>

                  <div className="flex -translate-y-4 gap-x-4 pb-24 md:pb-0">
                    <button className="shrink-0" onClick={previousStep}>
                      <NextImage
                        width={50}
                        height={50}
                        alt="previous"
                        src="/assets/navigation-arrow-right.svg"
                        className={cn(
                          'cursor-pointer',
                          'rotate-180',
                          currStep === 0 ? 'opacity-50' : 'opacity-100',
                        )}
                      />
                    </button>
                    <button className="shrink-0" onClick={nextStep}>
                      <NextImage
                        width={50}
                        height={50}
                        alt="previous"
                        src="/assets/navigation-arrow-right.svg"
                        className={cn(
                          'cursor-pointer',
                          currStep === tips.length - 1 ? 'opacity-50' : 'opacity-100',
                        )}
                      />
                    </button>
                  </div>
                </div>

                {/* DESKTOP */}

                <div className="hidden gap-x-4 md:flex">
                  <button className="shrink-0" onClick={previousStep}>
                    <NextImage
                      width={50}
                      height={50}
                      alt="previous"
                      src="/assets/navigation-arrow-right.svg"
                      className={cn(
                        'cursor-pointer',
                        'rotate-180',
                        currStep === 0 ? 'opacity-50' : 'opacity-100',
                      )}
                    />
                  </button>
                  <button className="shrink-0" onClick={nextStep}>
                    <NextImage
                      width={50}
                      height={50}
                      alt="previous"
                      src="/assets/navigation-arrow-right.svg"
                      className={cn(
                        'cursor-pointer',
                        currStep === tips.length - 1 ? 'opacity-50' : 'opacity-100',
                      )}
                    />
                  </button>
                </div>
              </div>

              <div className="hidden md:block">
                {tips[currStep].image && (
                  <SanityImage
                    data={tips[currStep].image as Image}
                    className="h-[324px] w-[324px]"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      }

      {/* BUTTON */}
      <div className="text-block blog-content-container container relative max-w-lg justify-items-start px-0 py-2 md:max-w-none">
        <div className="relative col-start-1 space-y-1em md:col-span-18 md:col-start-3">
          <OverlayTooltipButton label={label} onClick={() => setShowOverlay((prev) => !prev)} />
        </div>
      </div>
    </>
  )
}

export default TipsOverlay

import Image from 'next/image'

import Button from '@/components/ui/button'

const ProductList = ({ productSections }) => {
  if (!productSections?.length) return null

  return (
    <div className="hidden flex-col gap-[30px] pr-2 pt-[60px] md:flex">
      {productSections.map((section, index) => (
        <div key={index} className="flex flex-col gap-3">
          <h3>{section.sectionTitle}</h3>
          <p className="small-text text-balance">{section.description}</p>
          <ul className="m-0 flex flex-col gap-3">
            {section.products.map((product, index) => {
              const { minVariantPrice, maxVariantPrice } = product.store.priceRange
              const displayPrice =
                minVariantPrice === maxVariantPrice
                  ? `£${minVariantPrice.toFixed(2)}`
                  : `£${minVariantPrice.toFixed(2)} - £${maxVariantPrice.toFixed(2)}`

              return (
                <li key={index} className="flex items-center gap-5">
                  <div className="relative h-[116px] w-[116px] shrink-0 overflow-hidden">
                    <Image
                      src={product.store.previewImageUrl}
                      alt="product image"
                      width={116}
                      height={116}
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-5">
                    <div>
                      <p className="mb-1.5">{product.title}</p>
                      <p className="label-1">{displayPrice}</p>
                    </div>
                    <Button
                      variant="outline"
                      className="w-fit border border-beige-dark px-4 py-2.5"
                    >
                      Add to basket
                    </Button>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default ProductList

'use client'

import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'
import * as React from 'react'

import { cn } from '@/lib/utils'

import Icon from './icon'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn('border-b', className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

interface AccordionTriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  customIcon?: React.ReactNode
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, customIcon, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'relative flex flex-1 items-center justify-between py-4 font-medium hover:underline [&_svg]:transition-all',
        {
          '[&[data-state=closed]_svg]:rotate-180': !customIcon,
          '[&[data-state=open]_svg]:rotate-180': !!customIcon,
        },
        className,
      )}
      {...props}
    >
      {children}
      {customIcon || <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="text-sm overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn('pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

const StyledAccordionItem = ({ children, value, ...props }) => (
  <AccordionItem {...props} value={value} className="flex flex-col gap-2.5 [&&]:border-b-0">
    {children}
  </AccordionItem>
)

const StyledAccordionTrigger = ({ children, ...props }) => (
  <AccordionTrigger
    className="hover:no-underline"
    customIcon={<Icon className="absolute right-2" variant="arrow" />}
    {...props}
  >
    <div className="relative flex w-full items-center gap-4">
      <p className="nav-text-small w-max whitespace-nowrap no-underline">{children}</p>
      <div className="h-[1px] w-full grow bg-beige-dark"></div>
    </div>
  </AccordionTrigger>
)

export {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  StyledAccordionItem,
  StyledAccordionTrigger,
}

import Button from '@/components/ui/button'
import type { MethodAndIngredients } from '@/types'

import IngredientsAccordion from './ingredients-accordion'
import ProductList from './product-list'
import Servings from './servings'

type SidebarProps = Omit<MethodAndIngredients, 'content'> & {
  handleToggle: () => void
  handlePlus: () => void
  handleMinus: () => void
  open: boolean
}

const Sidebar = ({
  servingsCount,
  ingredients,
  productSections,
  handleToggle,
  open,
  handlePlus,
  handleMinus,
}: SidebarProps) => {
  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full scrollbar-hide md:overflow-auto md:pb-2 md:pt-[30px]">
        <h5>Ingredients</h5>
        <Servings servingsCount={servingsCount} handleMinus={handleMinus} handlePlus={handlePlus} />
        <IngredientsAccordion ingredients={ingredients} />
        {/* <div className="mt-6 flex flex-col gap-2.5 pr-5">
          <Button variant="secondary">Download recipe</Button>
        </div> */}
        <ProductList productSections={productSections} />
      </div>
      <div className="grow-1 hidden h-full w-[1px] bg-beige-dark md:block"></div>
      <button
        onClick={handleToggle}
        className={`mt-7 hidden h-fit w-fit rounded-r-[4px] border border-l-0 border-beige-dark px-[5px] py-3 md:block`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={`transition-transform duration-200 ${open ? 'rotate-0' : 'rotate-180'}`}
        >
          <path d="M17.41 7.41L12.83 12L17.41 16.59L16 18L10 12L16 6L17.41 7.41Z" fill="#353839" />
          <path d="M11.41 7.41L6.83 12L11.41 16.59L10 18L4 12L10 6L11.41 7.41Z" fill="#353839" />
        </svg>
      </button>
    </div>
  )
}

export default Sidebar

'use client'
import { useEffect, useState } from 'react'

import Media from '@/components/shared/media'
import { cn } from '@/lib/utils'
import type { MethodAndIngredients } from '@/types'

import TextBlock from '../text-block'
import MethodContent from './method-content'
import Sidebar from './sidebar'

const MethodAndIngredients = ({
  servingsCount,
  ingredients,
  productSections,
  content,
}: MethodAndIngredients) => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [servingsMulti, setServingsMulti] = useState(1)

  const handlePlus = () => {
    setServingsMulti((prev) => prev + 1)
  }
  const handleMinus = () => {
    setServingsMulti((prev) => (prev > 1 ? prev - 1 : 1))
  }

  const handleToggle = () => {
    setSidebarOpen((prev) => !prev)
  }

  const updatedIngredients = ingredients?.map((ingredientsItem) => {
    return {
      ...ingredientsItem,
      ingredients: ingredientsItem.ingredients?.map((ingredient) => {
        return {
          ...ingredient,
          amount: ingredient?.amount ? ingredient.amount * servingsMulti : null,
        }
      }),
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const openSidebar = () => {
        setSidebarOpen(true)
      }

      window.addEventListener('resize', openSidebar)

      return () => window.removeEventListener('resize', openSidebar)
    }
  }, [])

  return (
    <>
      <div className="container relative border-b border-beige-dark pb-16 md:grid md:grid-cols-20 md:grid-rows-1">
        <div
          className={cn(
            `z-10 col-start-1 col-end-6 flex translate-x-0 flex-col gap-6 pt-8 transition-transform duration-200 md:col-span-7 md:col-end-8 md:row-span-1 md:row-start-1 md:pt-0`,
            { '-translate-x-full': !sidebarOpen },
          )}
          id="ingredients"
        >
          <Sidebar
            handleToggle={handleToggle}
            open={sidebarOpen}
            servingsCount={servingsCount * servingsMulti}
            ingredients={updatedIngredients}
            productSections={productSections}
            _type={'methodAndIngredients'}
            handlePlus={handlePlus}
            handleMinus={handleMinus}
          />
        </div>

        <div
          className={cn(
            `col-span-20 col-start-1 pb-9 pt-10 md:col-span-12 md:col-start-5 md:row-span-1 md:row-start-1 md:pb-0`,
            { 'md:col-span-13 md:col-start-8': sidebarOpen },
          )}
          id="method"
        >
          {content && <MethodContent content={content} />}
        </div>
      </div>
    </>
  )
}

export default MethodAndIngredients

import { useState } from 'react'

import Button from '@/components/ui/button'

type ServingsProps = {
  servingsCount: number
  handlePlus: () => void
  handleMinus: () => void
}

const Servings = ({ servingsCount, handlePlus, handleMinus }: ServingsProps) => {
  return (
    <div className="flex items-center gap-3 py-2.5">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 17.0001V19.0001H2V17.0001C2 17.0001 2 13.0001 9 13.0001C16 13.0001 16 17.0001 16 17.0001ZM12.5 7.50005C12.5 6.80782 12.2947 6.13113 11.9101 5.55556C11.5256 4.97998 10.9789 4.53138 10.3394 4.26647C9.69985 4.00157 8.99612 3.93226 8.31718 4.0673C7.63825 4.20235 7.01461 4.5357 6.52513 5.02518C6.03564 5.51466 5.7023 6.1383 5.56725 6.81724C5.4322 7.49617 5.50152 8.1999 5.76642 8.83944C6.03133 9.47899 6.47993 10.0256 7.0555 10.4102C7.63108 10.7948 8.30777 11.0001 9 11.0001C9.92826 11.0001 10.8185 10.6313 11.4749 9.97493C12.1313 9.31855 12.5 8.42831 12.5 7.50005ZM15.94 13.0001C16.5547 13.4758 17.0578 14.0805 17.4137 14.7716C17.7696 15.4626 17.9697 16.2233 18 17.0001V19.0001H22V17.0001C22 17.0001 22 13.3701 15.94 13.0001ZM15 4.00005C14.3117 3.99622 13.6385 4.20201 13.07 4.59005C13.6774 5.43879 14.0041 6.45634 14.0041 7.50005C14.0041 8.54377 13.6774 9.56132 13.07 10.4101C13.6385 10.7981 14.3117 11.0039 15 11.0001C15.9283 11.0001 16.8185 10.6313 17.4749 9.97493C18.1313 9.31855 18.5 8.42831 18.5 7.50005C18.5 6.57179 18.1313 5.68156 17.4749 5.02518C16.8185 4.3688 15.9283 4.00005 15 4.00005Z"
          fill="#353839"
        />
      </svg>
      {servingsCount && <p>{servingsCount} servings</p>}
      <div className="flex items-center gap-2.5 border border-beige-dark px-3">
        <Button
          className="h-5 w-5 bg-transparent p-0"
          onClick={handleMinus}
          disabled={servingsCount < 1}
          variant="icon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path d="M15.8385 10.8337H4.17188V9.16699H15.8385V10.8337Z" fill="#353839" />
          </svg>
        </Button>
        <div className="h-8 w-[1px] bg-beige-dark"></div>
        <Button className="h-5 w-5 bg-transparent p-0" onClick={handlePlus} variant="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15.8385 10.8337H10.8385V15.8337H9.17188V10.8337H4.17188V9.16699H9.17188V4.16699H10.8385V9.16699H15.8385V10.8337Z"
              fill="#353839"
            />
          </svg>
        </Button>
      </div>
    </div>
  )
}

export default Servings

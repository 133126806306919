import { cn } from '@/lib/utils'
import type { BlogMedia } from '@/types'

import Media from '../shared/media'
import MythBackground from '../ui/myth-background'

interface BlogMediaProps extends BlogMedia {
  isMyth?: boolean
  isEvent?: boolean
  isIngredients?: boolean
  className?: string
  isDisabled?: boolean
}

const BlogMedia = ({
  media,
  fullWidth,
  isMyth,
  isEvent,
  isIngredients,
  className,
  isDisabled,
}: BlogMediaProps) => {
  if (!media) return null

  if (isDisabled) return null

  return (
    <div
      className={cn(
        'blog-media blog-content-container blog-module-spacing container relative',
        {
          'myth-spacing': isMyth,
          'my-0 px-20 py-6': isEvent,
          'px-0': isIngredients,
        },
        className,
      )}
    >
      <Media
        data={media}
        className={cn({
          'lg:col-span-10 lg:col-start-6': fullWidth,
          'px-[var(--container-padding)] lg:px-0': isMyth,
          'py-0 md:col-span-full md:col-start-1 lg:col-span-full lg:col-start-1': isEvent,
          'md:col-span-16 md:col-start-3': isIngredients,
        })}
        aspectRatio={fullWidth ? 'wide' : media.aspectRatio}
        captionClassName="relative block pb-2 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[1px]"
      />
      {isMyth && <MythBackground />}
    </div>
  )
}

export default BlogMedia

import Icon from '@/components/ui/icon'
import { cn } from '@/lib/utils'
import type { TextBlock } from '@/types'

import { CustomPortableText } from '../shared/custom-portable-text'
import MythBackground from '../ui/myth-background'

interface TextBlockProps extends TextBlock {
  isMyth?: boolean
  isHub?: boolean
  isEvent?: boolean
  isGifting?: boolean
  isIngredients?: boolean
  className?: string
  isDisabled?: boolean
}

const TextBlock = ({
  copy,
  isMyth,
  isHub,
  isEvent,
  isGifting,
  isIngredients,
  className,
  isDisabled,
}: TextBlockProps) => {
  if (!copy) return null

  if (isDisabled) return null

  return (
    <div
      className={cn(
        'text-block blog-content-container container relative',
        {
          'myth-spacing': isMyth,
          'my-0 px-5 py-6 md:px-12 xl:px-20': isEvent,
          'px-0 py-2': isIngredients,
          'blog-module-spacing': !isIngredients,
          'justify-items-start': isGifting,
        },
        className,
      )}
    >
      <CustomPortableText
        value={copy}
        className={cn('relative text-justify [&>*:first-child]:mt-0', {
          'px-[var(--container-padding)] lg:px-0': isMyth,
          'col-span-full max-w-full break-words text-left md:col-span-12 md:col-start-5': isHub,
          'md:col-span-14 md:col-start-1 lg:col-start-2 [&_h2]:leading-normal': isGifting,
          'col-span-full': isEvent,
          'max-w-full md:col-span-16 md:col-start-3': isIngredients,
        })}
        paragraphClasses={cn('mt-10 xl:mt-[3.75rem] [&+p]:mt-4 xl:[&+p]:mt-5', {
          'mt-[0px] text-wrap xl:start-0 xl:col-span-full xl:mt-[0px]': isHub,
        })}
        blockquoteClasses={cn('', {
          'grid-cols-full xl:grid-cols-full [&>img]:w-[30px] md:[&>img]:w-[40px]': isHub,
        })}
      />
      {isMyth && <MythBackground />}
    </div>
  )
}

export default TextBlock

import { cn } from '@/lib/utils'

import Divider from '../ui/divider'
import MythBackground from '../ui/myth-background'

const BlogDivider = ({ isMyth, className }: { isMyth?: boolean; className?: string }) => {
  if (isMyth) {
    return (
      <div
        className={cn(
          'blog-divider grid-container blog-content-container myth-spacing container relative',
          className,
        )}
      >
        <Divider type="shorter-outline-diamond" className="col-span-full" />
        {isMyth && <MythBackground />}
      </div>
    )
  }
  return <Divider type="shorter-outline-diamond" className="blog-module-spacing relative" />
}

export default BlogDivider

import { useState } from 'react'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Checkbox } from '@/components/ui/checkbox'

const IngredientsAccordion = ({ ingredients }) => {
  const [checkedItems, setCheckedItems] = useState({})

  const handleCheckboxChange = (key) => {
    setCheckedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }
  return (
    <Accordion type="multiple" className="w-full" defaultValue={['item-0']}>
      {ingredients?.map((ingredient, i) => (
        <AccordionItem
          key={`${ingredient.sectionTitle}-${i}`}
          value={`item-${i}`}
          className="!border-b-0"
        >
          <AccordionTrigger
            className="nav-text h-[45px] w-full"
            customIcon={
              <div className="absolute right-5 top-[5px] shrink-0 rounded-full border border-beige-dark bg-beige p-[5px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  className="transition-transform duration-200"
                >
                  <path
                    d="M7.41 15.8104L12 11.2304L16.59 15.8104L18 14.4004L12 8.40039L6 14.4004L7.41 15.8104Z"
                    fill="#353839"
                  />
                </svg>
              </div>
            }
          >
            <div className="relative flex h-full w-full items-center gap-4">
              <p className="shrink-0">{ingredient.sectionTitle}</p>
              <div className="h-[1px] w-full grow-[1px] bg-beige-dark"></div>
            </div>
          </AccordionTrigger>
          {ingredient.ingredients?.map((ingredientItem, i) => (
            <AccordionContent
              key={ingredientItem._key}
              className="mt-[] cursor-pointer pb-0"
              onClick={() => handleCheckboxChange(ingredientItem._key)}
            >
              <div
                className={`w-full ${i !== (ingredient.ingredients?.length ?? 0) - 1 ? 'border-b' : ''} border-beige-dark`}
              >
                <div className="flex w-full items-center justify-between gap-3 py-2.5 pr-7">
                  <p className="">
                    {ingredientItem.amount && `${ingredientItem.amount} `}
                    {ingredientItem.unit && `${ingredientItem.unit} `}
                    {ingredientItem.name && `${ingredientItem.name}`}
                  </p>
                  {/* place for cart btn */}
                  <Checkbox
                    customCheckmark={<div></div>}
                    className="h-[21px] w-5 border-0 data-[state=checked]:border-0 data-[state=checked]:bg-transparent data-[state=checked]:bg-[url(/assets/icons/checkmark.svg)] data-[state=unchecked]:bg-[url(/assets/icons/checkbox.svg)]"
                    checked={checkedItems[ingredientItem._key] || false}
                    onChange={() => handleCheckboxChange(ingredientItem._key)}
                  />
                </div>
              </div>
            </AccordionContent>
          ))}
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default IngredientsAccordion

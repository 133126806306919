import type { MethodContent } from '@/types'

import BlogDivider from '../blog-divider'
import BlogMedia from '../blog-media'
import TextBlock from '../text-block'
import TipsOverlay from '../tips-overlay'

const MethodContent = ({ content }: MethodContent) => {
  return (
    <>
      {content?.map((block, key) => {
        switch (block?._type) {
          case 'blogMedia':
            return <BlogMedia key={key} {...block} isIngredients />
          case 'textBlock':
            return <TextBlock key={key} {...block} isIngredients />
          case 'blogDivider':
            return <BlogDivider {...block} key={key} /> // grid grid-cols-subgrid
          case 'tipsOverlay':
            return <TipsOverlay {...block} key={key} />
          default:
            return null
        }
      })}
    </>
  )
}

export default MethodContent
